import { ENV } from "../../../../src/app/interfaces/interfaces";

export const environment:ENV = {
    production: false,
    apiUrl: 'https:// todotix.gateway.test.todotix.net/', //Test
    type: 'todotix',
    title: 'Todotix',
    subtitle: 'Eventos',
    description: `"Club Bolívar - Membresías" es un sitio en el que puedes administrar tus membresías, como las de socios, entradas y abonos.`,
    keywords: 'club, bolivar, campeon, clubbolivar, membresias, socios, abonos, entradas, tickets',
    yearProyect: '2024',
    imgLogo: '/assets/img/logo_min.png',
    imgLogoSVG: '/assets/img/isologo.svg',
    imgLogoWhiteSVG: '/assets/img/isologo-white.svg',
    imgLogoWhite: '/assets/img/logo-white.png',

    authRedirect: '',

    // Seats IO
    region: 'sa',

    keyEncript: '12345678901234567890123456789012',
    ivEncript: '1234567890123456',
};
