import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';


import { getEnvironment } from '../../../../src/app/environment/environment';
import { ENV } from '../../../../src/app/interfaces/interfaces';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    NgClass,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'todotix';
  env:ENV = getEnvironment();

  constructor(
    public router: Router,
  ) {
  }
}
