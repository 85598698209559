import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { mainAppConfigProviders } from '../../../../src/app/app.config';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    ...mainAppConfigProviders
  ]
};
