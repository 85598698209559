import { Routes } from '@angular/router';

export const routes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
    },
    // {
    //     path: 'characteristics',
    //     loadComponent: () => import('./modules/characteristics/pages/characteristics/characteristics.page').then( m => m.CharacteristicsPage)
    // },
    // //* General Pages
    {
        path: '',
        loadComponent: () => import('./layouts/general/general.component').then((m) => m.GeneralLayout),
        loadChildren: () => import('./routes/app.routes'),
    },
    // //* Auth routes
    {
        path: '',
        loadChildren: () => import('./routes/auth.routes'),
    },
    // // //* Error Pages
    // {
    //     path: 'error',
    //     loadChildren: () => import('./routes/error.routes'),
    // },
    {
        path: '**',
        redirectTo : '/home',
        pathMatch: 'full',
    },
];
